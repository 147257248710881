import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'

import { Header } from '.'
import { Footer } from '.'
import { Slider } from '.'
import config from '../../utils/siteConfig'

// Styles
import '../../styles/app.css'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ settingsData, children, bodyClass, isPostPage }) => {
    const site = settingsData.allGhostSettings.edges[0].node

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} filter="visibility:internal" />
                <script src={`/js/index.js`} type="text/javascript" />
                <script src={`/js/global.js`} type="text/javascript" />
                <script src={`/js/ityped.js`} type="text/javascript" />
            </Helmet>

            <div className="global-wrap">
                <div className="section-content-wrap">
                    {/* The main header section on top of the screen */}
                    <Header settingsData={settingsData} isPostPage={isPostPage} />

                    <div className="section-content-wrap">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </div>

                </div>

                {/* The footer at the very bottom of the screen */}
                <Footer data={settingsData} />

            </div>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isPostPage: PropTypes.bool,
    settingsData: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout settingsData={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
