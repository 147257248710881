import React from 'react'
import PropTypes from 'prop-types'
import { Link, Img } from 'gatsby'

const Header = ({ settingsData, isPostPage }) => {
    const site = settingsData.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null
    // const isPost = data.ghostPost ? true : false
    // const isPage = data.ghostPage ? true : false
    // const isPostPage = isPost || isPage ? true : false
    // console.log("ghostPost: ", data.ghostPost)
    // console.log("ghostPage: ", data.ghostPage)
    // console.log("data: ", data)
    // console.log("isPost: ", isPost)
    // console.log("isPage: ", isPage)
    // console.log("isPostPage: ", isPostPage)
    // console.log(`site: `, site.navigation.url)
    
    return (
        <>
            <div className="section-header wrap">
                <header className="header-wrap flex">
                    <div className="header-logo">
                        { isPostPage ? 
                            <div className={site.logo ? "is-logo" : "no-logo"}>
                                <Link to={site.navigation.url}>
                                    <img src={site.logo} alt={site.title} />
                                </Link>
                            </div> : 
                            <h1 className={site.logo ? "is-logo" : "no-logo"}>
                                <Link to={site.navigation.url}>
                                    <img src={site.logo} alt={site.title} />
                                </Link>
                            </h1>
                        }
                    </div>
                    <div className="header-nav">
                        <nav className="nav-wrap">
                            <label htmlFor="toggle" className="nav-label hamburger hamburger-minus">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </label>
                            <input type="checkbox" id="toggle" className="nav-toggle"/>
                            <ul className="nav-list">
                                { site.navigation.map((navItem) => (
                                    <li className="nav-list-item" key={navItem.id}>
                                        <Link className="nav-link" to={navItem.url} target="_blank">{navItem.label}</Link>
                                    </li>
                                )
                                )}
                            </ul>
                        </nav>
                    </div>
                    { site.description ? 
                        <div className="blog-description flex">{site.description}</div> : 
                        null
                    }
                </header>
            </div>
        </>
    )
}

Header.propTypes = {
    isPostPage: PropTypes.bool,
    settingsData: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

export default Header