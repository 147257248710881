import React from 'react'
import PropTypes from 'prop-types'

const printScripts = ({ isPostPage }) => {
    return (
        <>
            {/* <script src={withPrefix('js/index.js')} type="text/javascript" />
            { isPostPage ? 
                <script src={withPrefix('js/post.js')} type="text/javascript" /> : 
                null}
            <script src="js/global.js" type="text/javascript" />
            <script src={withPrefix('js/ityped.js')} type="text/javascript" /> */}
        </>
    )
}

printScripts.propTypes = {
    isPostPage: PropTypes.bool.isRequired,
}

export default printScripts