import React from 'react'
import PropTypes from 'prop-types'
import { Navigation } from '.'
import { Link } from 'gatsby'

const Footer = ({ data }) => {
    const site = data.allGhostSettings.edges[0].node
    return (
        <>
            <footer className="section-footer">
                <div className="footer-wrap wrap flex">
                    <div className="footer-nav">
                        <ul className="nav-list">
                            { site.navigation.map((navItem) => (
                                <li className="nav-list-item" key={navItem.id}>
                                    <Link className="nav-link" to={navItem.url} target="_blank" rel="noopener noreferrer">{navItem.label}</Link>
                                </li>
                            )
                            )}
                        </ul>
                    </div>
                    <div className="footer-copyright">
                        <Link to="/">{site.title}</Link> &copy; 2020
                    </div>
                </div>
            </footer>
        </>
    )
}

Footer.propTypes = {
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

export default Footer