import React from 'react'
import PropTypes from 'prop-types'
import TimeAgo from 'react-timeago'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const PostCard = ({ post }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)
    // console.log("post: ", post)

    return (
        <>
            <div className={"item-wrap post flex" + (post.featured ? " is-image" : " no-image")}>
                <article>
                    <Link to={url} className="item-link-overlay" aria-label={post.title} />
                    { post.feature_image ? 
                        <div className="item-image" style={{"backgroundImage" : `url(${post.feature_image})`}}></div> : null }
                    <h2><a href={url} className="white">{post.title}</a></h2>
                    <div className="item-meta white">
                        <a className="author-name white" href={url}>{post.name}</a>
                        {/* <a className="author-name white" href="{{url}}">{{name}}</a><span> {{t "and others"}}</span> */}
                        {/* <time datetime="{{date format="YYYY-MM-DD"}}">{{date published_at timeago="true"}}</time> */}
                    </div>
                    <a className="primary-tag global-tag white" href={url}>{post.name}</a>
                    <div className={"item-meta white" + ( post.primary_tag ? " is-primary-tag" : null )}>
                        <span>by </span>
                        <a className="author-name white" href={ `/author/` + post.primary_author.slug }>{post.primary_author.name} </a>
                        <TimeAgo date={post.created_at} />
                    </div>
                    { post.primary_tag ?
                        <a className="primary-tag global-tag white" href={url}>{post.primary_tag.name}</a>
                    : null}

                </article>
            </div>
        </>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard
